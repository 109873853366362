var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.category.videos && _vm.category.videos.length != 0)?_c('div',[_c('h2',{staticClass:"main mb-0"},[_vm._v(" "+_vm._s(_vm.category.categDetails.categoryTitle)+" ")]),_c('hr'),_c('div',{staticClass:"card-carousel-wrapper"},[_c('div',{staticClass:"card-carousel-nav-left",class:{
           'hidden': _vm.category.videos &&
           _vm.category.videos.length <= _vm.columnItems
           },attrs:{"disabled":_vm.atHeadOfList},on:{"click":function($event){return _vm.moveCarousel(-1)}}}),_c('div',{staticClass:"card-carousel"},[_c('div',{staticClass:"card-carousel-cards",style:({transform: 'translateX(' + _vm.currentOffset + 'px)'})},_vm._l((_vm.category.videos),function(video,index){return _c('app-card',{key:index,staticClass:"card-carousel-card",attrs:{"videos":video,"columnItems":_vm.columnItems,"cardCarouselWidth":_vm.cardCarouselWidth}})}),1)]),_c('div',{staticClass:"card-carousel-nav-right",class:{
           'hidden': _vm.category.videos &&
           _vm.category.videos.length <= _vm.columnItems
           },attrs:{"disabled":_vm.atEndOfList},on:{"click":function($event){return _vm.moveCarousel(1)}}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }