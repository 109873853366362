<template>
  <div v-if="category.videos && category.videos.length != 0">
    <h2 class="main mb-0"> {{ category.categDetails.categoryTitle }} </h2>
    <hr>
    <div class="card-carousel-wrapper">
      <!-- Nav Left -->
      <div class="card-carousel-nav-left"
           @click="moveCarousel(-1)"
           :disabled="atHeadOfList"
           :class="{
             'hidden': category.videos &&
             category.videos.length <= columnItems
             }">
      </div>

      <!-- Card -->
      <div class="card-carousel">
        <div class="card-carousel-cards"
             :style="{transform: 'translateX(' + currentOffset + 'px)'}">
          <app-card class="card-carousel-card"
                    v-for="(video, index) in category.videos"
                    :key="index"
                    :videos="video"
                    :columnItems="columnItems"
                    :cardCarouselWidth="cardCarouselWidth">
          </app-card>
        </div>
      </div>

      <!-- Nav Right -->
      <div class="card-carousel-nav-right"
           @click="moveCarousel(1)"
           :disabled="atEndOfList"
           :class="{
             'hidden': category.videos &&
             category.videos.length <= columnItems
             }">
      </div>
    </div>
  </div>
</template>

<script>
  const AppCard = () => import('@/components/user/common/Card.vue');
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    name  : 'Carousel',
    props : {
      category : Object,
    },
    components : {
      AppCard,
    },
    data() {
      return {
        currentOffset     : 0,
        cardCarouselWidth : 0,
      }
    },
    mounted() {
      this.$nextTick(function() {
        this.cardCarouselWidth =
          this.$el.getElementsByClassName('card-carousel-cards')[0].clientWidth;
      });
    },
    updated() {
      this.cardCarouselWidth =
        this.$el.getElementsByClassName('card-carousel-cards')[0].clientWidth;
      this.getWindowWidth();
    },
    computed : {
      atEndOfList() {
        return this.currentOffset <= ((this.paginationFactor * -1) *
          (this.category.videos.length - this.columnItems));
      },
      atHeadOfList() {
        return this.currentOffset >= 0;
      },
      paginationFactor() {
        return Math.round(this.cardCarouselWidth / this.columnItems);
      },
    },
    methods : {

      /**
       * Move Carousel either left or right
       *
       * @param {integer} direction either -1 (left) or 1 (right)
       */
      moveCarousel(direction) {
        // Find a more elegant way to express the :style.
        //  consider using props to make it truly generic
        if (direction === 1 && !this.atEndOfList)
          this.currentOffset -= this.paginationFactor;

        else if (direction === -1 && !this.atHeadOfList)
          this.currentOffset += this.paginationFactor;
      },
    },
    mixins : [
      BreakpointMixin,
    ],
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/common/carousel";
</style>
